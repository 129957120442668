import React from 'react'
import { graphql } from 'gatsby'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import Wrapper from '../../components/wrapper'
import JobDetailWithCompanyDetail from '../../components/pageComponents/jobDetailWithCompany'
import { permissionsHandler } from '../../functions'

const JobDetailWithEmployerTemplate = ({ location, data }) => {
  const storeProducts = useSelector(state => state.user).products
  const Children = props => {
    const {
      user,
      userProfile,
      userRole,
      loadUserProfile,
      setUserProfile,
      openLoginModal,
      openSignupModal,
    } = props
    let products = permissionsHandler(storeProducts, user?.role?.id)
    let isAllowed = products?.subscriptionPurchased

    return (
      <>
        <Helmet>
          <title>{data?.strapiJob?.title || ''}</title>
          <meta property="og:title" content={data?.strapiJob?.title || ''} />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={data?.strapiJob?.title || ''} />

          {data?.strapiJob?.google_structured_data && (
            <script type="application/ld+json">
              {JSON.stringify(data?.strapiJob?.google_structured_data)}
            </script>
          )}
        </Helmet>
        <JobDetailWithCompanyDetail
          loadUserProfile={loadUserProfile}
          userProfile={userProfile}
          user={user}
          setUserProfile={setUserProfile}
          location={location}
          jobData={data?.strapiJob}
          isAllowed={isAllowed}
          role={userRole}
          openLoginModal={openLoginModal}
          openSignupModal={openSignupModal}
        />
      </>
    )
  }

  return (
    <Wrapper location={location}>
      <Children />
    </Wrapper>
  )
}

export default JobDetailWithEmployerTemplate
export const pageQuery = graphql`
  query ($slug: String!) {
    strapiJob(slug: { eq: $slug }, archived_date: { eq: null }) {
      id
      title
      created_at
      slug
      description
      isPremium
      employer {
        id
        name
        staffing_firm
        image {
          id
          name
          url
        }
      }
      employmentType
      educationLevel
      applicationUrl
      maxSalary
      minSalary
      currency
      primary_function
      compensation_type
      is_deleted
      job_code
      archived_date
      external_redirect
      is_template
      scraped_id
      google_structured_data
      published_at
      min_compensation
      max_compensation
      travel_percentage
      application_method
      remote_enabled
      product_category
      location
      strapiId
      exclusive_medreps
      confidential
    }
  }
`
